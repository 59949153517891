import React from "react";

import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import List from "../components/List";

export default function Home() {
  return (
    <div className="md:h-screen md:overflow-hidden">
      <div className="">
        <Navbar />
        <Hero />
        <List />
      </div>
      <div className="bg-black h-12"></div>
    </div>
  );
}
