import React from "react";

export default function Navbar() {
  return (
    <div className="m-4 border-b pb-6 sm:my-8 sm:mx-12">
      <p className="font-bold text-4xl">JELLYBEAN LAB</p>
      <p className="text-sm font-light">
        Level 3, 55 Pyrmont Bridge Rd, Pyrmont NSW
      </p>
    </div>
  );
}
