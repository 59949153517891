import React from "react";

import Logo1 from "../assets/Logo1.png";
import Logo2 from "../assets/Logo2.png";
import Logo3 from "../assets/Logo3.png";
import Logo4 from "../assets/Logo4.png";
import Logo5 from "../assets/Logo5.png";
import Logo6 from "../assets/Logo6.png";
import Logo7 from "../assets/Logo7.png";
import Logo8 from "../assets/Logo8.png";
import Logo9 from "../assets/Logo9.png";
import Logo10 from "../assets/Logo10.png";
import Logo11 from "../assets/Logo11.png";
import Logo12 from "../assets/Logo12.png";
import Logo13 from "../assets/Logo13.png";
import Logo14 from "../assets/Logo14.png";
import Logo15 from "../assets/Logo15.png";
import Logo16 from "../assets/Logo16.png";
import Logo17 from "../assets/Logo17.png";
import Logo18 from "../assets/Logo18.png";

export default function Hero() {
  return (
    <div className="m-4 sm:my-8 sm:mx-12 sm:flex sm:flex-row">
      <div className="flex flex-col sm:align-top">
        <p className="sm:pr-8 sm:leading-tight font-semibold font-lora text-3xl sm:text-5xl sm:font-medium">
          Jellybean Lab is an agency that grows education companies.{" "}
          <span className="font-normal text-lg mt-2 flex sm:text-5xl sm:font-medium sm:inline">
            We empower Australian and global schools to impact millions of
            students.
          </span>
        </p>
      </div>
      <div className="mt-16 sm:mt-0 sm:pl-12">
        <div className="my-4 w-80 flex flex-row justify-between">
          <div className="">
            <p className="font-thin text-sm uppercase">Founded</p>
            <p className="">2018</p>
          </div>
          <div className="">
            <p className="font-thin text-sm uppercase">Headquarters</p>
            <p className="">Sydney AU</p>
          </div>
          <div className="">
            <p className="font-thin text-sm uppercase">Contact</p>
            <p className="">
              <a href="mailto: david@jellybeanlab.com.au">Email Us</a>
            </p>
          </div>
        </div>
        <div className="">
          <p className="mt-8 font-thin text-sm uppercase">Our Work</p>
          <p className="">
            In short, we’re a diverse group of like-minded people - strategists,
            marketers, engineers, designers and creatives who care deeply about
            the world of education. We believe learning is a lifelong endeavour
            and that the right education delivered at the right time in the
            right way has the power to unlock the human potential.
            <br />
            <br />
            Since launch, we've helped grow some of the most exciting education
            companies in the world. From venture backed edTech startups to
            global enterprises, local non-profits to government-backed private
            colleges, we've definitely helped make a splash. And we're only
            getting started.
          </p>
          <div className="grid grid-cols-6 text-center items-center mt-6">
            <img className="w-3/4" alt="logo" src={Logo1} />
            <img className="w-3/4" alt="logo" src={Logo2} />
            <img className="w-3/4" alt="logo" src={Logo3} />
            <img className="w-3/4" alt="logo" src={Logo4} />
            <img className="w-3/4" alt="logo" src={Logo5} />
            <img className="w-3/4" alt="logo" src={Logo6} />
            <img className="w-1/2" alt="Project Everest" src={Logo7} />
            <img className="w-3/4" alt="logo" src={Logo8} />
            <img className="w-3/4" alt="logo" src={Logo9} />
            <img className="w-3/4" alt="logo" src={Logo10} />
            <img className="w-3/4" alt="logo" src={Logo11} />
            <img className="w-3/4" alt="logo" src={Logo12} />
            <img className="w-3/4" alt="logo" src={Logo13} />
            <img className="w-3/4" alt="logo" src={Logo14} />
            <img className="w-3/4" alt="logo" src={Logo15} />
            <img className="w-3/4" alt="logo" src={Logo16} />
            <img className="w-1/2" alt="CGA" src={Logo17} />
            <img className="w-3/4" alt="logo" src={Logo18} />
          </div>
        </div>
      </div>
    </div>
  );
}
